import React from 'react'
import { useEffect } from 'react'
import { WEBNAME } from '../Consts'

const Cancel = () => {
  useEffect(() => {
    document.title = WEBNAME + " | Payment Cancelled"
  }, [])
  return (
    <div className='cancel' style={{"color" : "#fff", "fontSize" : "25px", "margin" : "20px", "textAlign" : "center"}}>
        Your Payment was Cancel!
        <span>Go Back to <a href="/subscriptions">subscriptions</a></span>
    </div>
  )
}

export default Cancel