import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Navbar from '../components/global/Navbar'
import Loader from '../components/loader/Loader'
import { API_URL, checkLogin, getCookieValue, HEADERS } from '../Consts'
import { faComment, faFolder, faUser } from '@fortawesome/free-solid-svg-icons'
import './HomeBlogs.css'
const HomeBlogs = () => {
    const [documentLoading, setDocumentLoading] = useState(true)
    const [showAddBlog, setShowAddBlog] = useState(false)
    const [showComments, setShowComments] = useState(false)
    const [blogs, setBlogs] = useState([])
    const [comments, setComments] = useState([])
    const [comment, setComment] = useState([])
    const [blogTitle, setBlogTitle] = useState("")
    const [blogDescription, setBlogDescription] = useState("")
    const [blogImage, setBlogImage] = useState("")

    const [showBlog, setShowBlog] = useState(false)

    const [blogData, setBlogData] = useState({})

    const [err, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)


    const handleAdmin = () => {

        var userType = getCookieValue("user_type")
        console.log("User :", userType)
        if (userType == " admin") {
            return true
        }
        else {
            return false
        }

    }

    useEffect(() => {
        var adminStatus = handleAdmin()
        setAdmin(adminStatus)
        console.log(adminStatus)
        document.title = "Emo Betting | Blogs"
        getBlogs()

    }, [])

    const getBlogs = () => {
        setDocumentLoading(true)
        axios.get(
            API_URL + "get_blogs",
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Access-Control-Allow-Credentials': 'true'
                }
            }
        ).
            then(res => {
                setDocumentLoading(false)
                console.log(res.data)
                setBlogs(res.data)
            })
    }



    const getNoOfComments = (blogid) => {
        var count = 1
        axios.get(API_URL + `getnoofcomments?blogid=${blogid}`, {
            headers: HEADERS
        }).
            then(res => {
                console.log(res.data)
                count = parseInt(res.data)

            })

        return count


    }


    return (


        <div className="blogs-container">
            {
                blogs.length < 1 ? <span className='noBlogs'>No Blogs to Show</span> :
                    blogs.map(blog => (
                        <div className="blog" onClick={() => document.location.href = "/blogs"} style={{"cursor" : "pointer"}}>
                            <div className='blog-img'>
                                <img src={blog.image} alt="" />

                                <div className='blog-overlay-details'>
                                    <h2>{blog.blog_title}</h2>

                                    <div className='blog-detail-icons'>
                                        <span><FontAwesomeIcon icon={faUser} className="icon" />Admin</span>
                                        <span><FontAwesomeIcon icon={faComment} className="icon" />{getNoOfComments(blog.blogid)}</span>
                                    </div>
                                </div>

                            </div>

                            <div className="blog-details">
                                <p>{blog.blog_description.length > 100 ? blog.blog_description.slice(0, 100) + "..." : blog.blog_description}</p>
                                <span>{blog.dop}</span><br /><br />
                                <div className='blog-details-buttons'>

                                </div>
                            </div>

                        </div>
                    ))
            }


        </div>


    )
}

export default HomeBlogs