import '@stripe/stripe-js'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Upcoming from './pages/Upcoming';
import Sportsbook from './pages/Sportsbook';
import Sports from './pages/Sports';
import Blogs from './pages/Blogs';
import Signup from './pages/signup/Signup';
import Login from './pages/login/Login';
import ArbitragePrematch from './pages/arbitrage prematch/ArbitragePrematch';
import Subscribe from './subscribe/Subscribe';
import Cancel from './pages/Cancel';
import Success from './pages/Success';
import ForgetPassword from './pages/ForgetPassword';
import Calculator from './components/calculator/Calculator';
import Loader from './components/loader/Loader';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/upcoming" element={<Upcoming />} />
          <Route path="/sportsbooks" element={<Sportsbook />} />
          <Route path="/sport" element={<Sports />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/arbitrage/prematch" element={<ArbitragePrematch />} />
          <Route path="/subscriptions" element={<Subscribe />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route path="/success" element={<Success />} />
          <Route path="/forget_password" element={<ForgetPassword />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/loader" element={<Loader />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
