import React, { useEffect, useState } from 'react'
import './Navbar.css'
import logo from '../../images/logo.png'
import menu from '../../images/menubar.png'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { API_KEY, checkLogin, getCookieValue } from '../../Consts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAmericanSignLanguageInterpreting, faBaseball, faBasketball, faFootball, faGolfBallTee, faHockeyPuck, faSoccerBall, faTableTennis } from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {

    const [showSport, setShowSport] = useState(false)
    const [showArbitrage, setShowArbitrage] = useState(false)
    const [sports, setSports] = useState([])
    const [showUsername, setShowUsername] = useState("")
    const [isLogged, setIsLogged] = useState(false)
    const [showResNav, setShowResNav] = useState(false)

    useEffect(() => {
        axios.get(`https://api.the-odds-api.com/v4/sports?apiKey=${API_KEY}&all=false`).
            then(res => {
                // console.log(res.data)
                setSports(res.data)
            })
    }, [])

    useEffect(() => {
        if (checkLogin()) {
            setIsLogged(true)
            setShowUsername(getCookieValue("username"))
        }
        else{
            setIsLogged(false)
        }
    }, [])

    const handleLogout = () => {
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "userid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "user_type=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "__stripe_mid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "__stripe_sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        setIsLogged(false)
        setShowUsername("")
        window.location.href = "/"
    }

    return (
        <>
            {
                showResNav ?
                    <div className="res-nav-container">
                        <span onClick={() => setShowResNav(false)}>X</span>
                        <div className="res-nav-links">
                            <div className="res-nav-link" onClick={() => window.document.location.href = "/"}>Home</div>
                            <div className="res-nav-link" onClick={() => window.document.location.href = "/upcoming"}>Upcoming</div>
                            <div className="res-nav-link" onClick={() => setShowSport(!showSport)}>Sports</div>
                            {
                                showSport &&

                                <div className="res-sports-popup">
                                    <div className='res-sports'>
                                        <div className="res-sport">
                                            <div className="res-sport-title">
                                                <FontAwesomeIcon icon={faFootball} />
                                                <span>American Football</span>
                                            </div>
                                            <div className="res-sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "American Football").map(filteredSport => (
                                                        <div className="res-sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>

                                        <div className="res-sport">
                                            <div className="res-sport-title">
                                                <FontAwesomeIcon icon={faBaseball} />
                                                <span>Baseball</span>
                                            </div>
                                            <div className="res-sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "American Football").map(filteredSport => (
                                                        <div className="res-sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="res-sport">
                                            <div className="res-sport-title">
                                                <FontAwesomeIcon icon={faBasketball} />
                                                <span>Basketball</span>
                                            </div>
                                            <div className="res-sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Basketball").map(filteredSport => (
                                                        <div className="res-sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="res-sport">
                                            <div className="res-sport-title">
                                                <FontAwesomeIcon icon={faTableTennis} />
                                                <span>Cricket</span>
                                            </div>
                                            <div className="res-sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Cricket").map(filteredSport => (
                                                        <div className="res-sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="res-sport">
                                            <div className="res-sport-title">
                                                <FontAwesomeIcon icon={faHockeyPuck} />
                                                <span>Ice Hockey</span>
                                            </div>
                                            <div className="res-sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Ice Hockey").map(filteredSport => (
                                                        <div className="res-sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="res-sport">
                                            <div className="res-sport-title">
                                                <FontAwesomeIcon icon={faAmericanSignLanguageInterpreting} />
                                                <span>Mixed Martial Arts</span>
                                            </div>
                                            <div className="res-sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Mixed Martial Arts").map(filteredSport => (
                                                        <div className="res-sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="res-sport">
                                            <div className="res-sport-title">
                                                <FontAwesomeIcon icon={faGolfBallTee} />
                                                <span>Golf</span>
                                            </div>
                                            <div className="res-sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Golf").map(filteredSport => (
                                                        <div className="res-sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="res-sport">
                                            <div className="res-sport-title">
                                                <i class="fa-regular fa-rugby-ball"></i>
                                                <span>Rugby League</span>
                                            </div>
                                            <div className="res-sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Rugby League").map(filteredSport => (
                                                        <div className="res-sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="res-sport">
                                            <div className="res-sport-title">
                                                <FontAwesomeIcon icon={faSoccerBall} />
                                                <span>Soccer</span>
                                            </div>
                                            <div className="res-sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Soccer").map(filteredSport => (
                                                        <div className="res-sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="res-nav-link" onClick={() => window.document.location.href = "/sportsbooks"}>Sportsbooks</div>
                            <div className="res-nav-link" onClick={() => setShowArbitrage(!showArbitrage)}>Arbitrage</div>
                            {
                                showArbitrage ?
                                    <div style={{ "textAlign": "center" }}>
                                        <div className='arbitrage-nav' style={{ "color": "#999", "fontWeight": "500" }} onClick={() => document.location.href = "/arbitrage/prematch"}>Arbitrage Prematch</div>
                                        <div className='arbitrage-nav' style={{ "color": "#999", "fontWeight": "500" }}>Arbitrage Bot <small style={{ "backgroundColor": "#25b05d", "padding": "5px", "color": "#fff", "fontSize": "10px", "borderRadius": "50px" }}>Coming soon</small></div>
                                    </div>
                                    : ""
                            }


                            <div className="res-nav-link" onClick={() => window.document.location.href = "/blogs"}>Blogs</div>
                            <div className="res-nav-link" onClick={() => window.document.location.href = "/subscriptions"}>Pricing</div>
                        </div>

                        <div className="res-auth">
                            {
                                isLogged ?
                                    <button id='logout' className='btn' onClick={() => handleLogout()}>Logout</button>
                                    :
                                    <>
                                        <button id='login' className='btn' onClick={() => window.document.location.href = "/login"}>Login</button>
                                        <button id='signup' className='btn' onClick={() => window.document.location.href = "/signup"}>SignUp</button>
                                    </>

                            }


                        </div>
                    </div>
                    : ""
            }


            <div className='navbar-container'>
                <div className="nav-left" onClick={() => window.document.location = "/"}>
                    <img src={logo} alt="" />
                </div>



                <div className="navs">
                    <NavLink exact activeClassName="active" to="/" className="nav">
                        HOME
                    </NavLink>
                    <NavLink activeClassName="active" to="/upcoming" className="nav">
                        UPCOMING
                    </NavLink>
                    <div activeClassName="active" className={showSport ? "nav nav-sport active" : "nav nav-sport"} onMouseLeave={() => setShowSport(false)} onMouseEnter={() => setShowSport(true)}>
                        SPORTS

                        {
                            showSport ?
                                <div className="sports-popup">
                                    <div className='sports'>
                                        <div className="sport">
                                            <div className="sport-title">
                                                <FontAwesomeIcon icon={faFootball} />
                                                <span>American Football</span>
                                            </div>
                                            <div className="sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "American Football").map(filteredSport => (
                                                        <div className="sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>

                                        <div className="sport">
                                            <div className="sport-title">
                                                <FontAwesomeIcon icon={faBaseball} />
                                                <span>Baseball</span>
                                            </div>
                                            <div className="sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "American Football").map(filteredSport => (
                                                        <div className="sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="sport">
                                            <div className="sport-title">
                                                <FontAwesomeIcon icon={faBasketball} />
                                                <span>Basketball</span>
                                            </div>
                                            <div className="sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Basketball").map(filteredSport => (
                                                        <div className="sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="sport">
                                            <div className="sport-title">
                                                <FontAwesomeIcon icon={faTableTennis} />
                                                <span>Cricket</span>
                                            </div>
                                            <div className="sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Cricket").map(filteredSport => (
                                                        <div className="sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="sport">
                                            <div className="sport-title">
                                                <FontAwesomeIcon icon={faHockeyPuck} />
                                                <span>Ice Hockey</span>
                                            </div>
                                            <div className="sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Ice Hockey").map(filteredSport => (
                                                        <div className="sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="sport">
                                            <div className="sport-title">
                                                <FontAwesomeIcon icon={faAmericanSignLanguageInterpreting} />
                                                <span>Mixed Martial Arts</span>
                                            </div>
                                            <div className="sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Mixed Martial Arts").map(filteredSport => (
                                                        <div className="sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="sport">
                                            <div className="sport-title">
                                                <FontAwesomeIcon icon={faGolfBallTee} />
                                                <span>Golf</span>
                                            </div>
                                            <div className="sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Golf").map(filteredSport => (
                                                        <div className="sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="sport">
                                            <div className="sport-title">
                                                <i class="fa-regular fa-rugby-ball"></i>
                                                <span>Rugby League</span>
                                            </div>
                                            <div className="sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Rugby League").map(filteredSport => (
                                                        <div className="sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                        <div className="sport">
                                            <div className="sport-title">
                                                <FontAwesomeIcon icon={faSoccerBall} />
                                                <span>Soccer</span>
                                            </div>
                                            <div className="sub-sports">

                                                {
                                                    sports.filter(sport => sport.group == "Soccer").map(filteredSport => (
                                                        <div className="sub-sport" onClick={() => window.location.href = `/sport?sport_id=${filteredSport.key}&sport_title=${filteredSport.title}`}>{filteredSport.title}</div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                : ""

                        }

                    </div>
                    <NavLink activeClassName="active" to="/sportsbooks" className="nav">
                        SPORTSBOOKS
                    </NavLink>
                    <div activeClassName="active" className={showArbitrage ? "nav nav-arbitrage active" : "nav nav-arbitrage"} onMouseLeave={() => setShowArbitrage(false)} onMouseEnter={() => setShowArbitrage(true)}>
                        ARBITRAGE
                        {
                            showArbitrage ?
                                <div className='arbitrage-container'>
                                    <div className='arbitrage-nav' onClick={() => document.location.href = "/arbitrage/prematch"}>Arbitrage Prematch</div>
                                    <div className='arbitrage-nav' >Arbitrage Bot <small style={{ "backgroundColor": "#25b05d", "padding": "5px", "color": "#fff", "fontSize": "10px", "borderRadius": "50px" }}>Coming soon</small></div>
                                </div>
                                : ""
                        }

                    </div>
                    <NavLink activeClassName="active" to="/blogs" className="nav">
                        BLOGS
                    </NavLink>
                    <NavLink activeClassName="active" to="/subscriptions" className="nav">
                        PRICING
                    </NavLink>

                </div>

                <div className="nav-right">

                    {
                        isLogged ?
                            <>
                                <button className='btn login-btn' onClick={() => handleLogout()}>Log Out</button>
                                <span>{showUsername}</span>
                            </>
                            :
                            <>
                                <button className='btn login-btn' onClick={() => document.location.href = "/login"}>Login</button>
                                <button className='btn signup-btn' onClick={() => document.location.href = "/signup"}>SignUp</button>
                            </>
                    }



                </div>
            </div>

            <div className='res-nav'>
                <div onClick={() => window.document.location = "/"}>
                    <img src={logo} alt="" />
                </div>

                <div onClick={() => setShowResNav(true)}>
                    <img src={menu} alt="" />
                </div>
            </div>
        </>

    )
}

export default Navbar
