export const API_KEY = "8f463657ea013ee9cd203b6ad961b6fa"
export const API_URL = "https://odd-national.herokuapp.com/"
// export const API_URL = "http://localhost:8000/"



// Oddnational63@gmail.com
// ecxmtebxlewukxkj
export const WEBNAME = "ODD NATIONAL"

export function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var date = date + ' ' + month + ' ' + year + ' '
  var time = hour + ':' + min + ':' + sec;
  return {
    "time": time,
    "date": date
  };
}

export const getCookieValue = (splitter) => {
  var value = "";

  if (document.cookie) {
    if (document.cookie.split(";")[0].split("=")[0] == "__stripe_mid") {
      if (splitter == "username") {
        value = document.cookie.split(";")[2]

        if (value) {
          value = value.replace(splitter + "=", "");
        }
        return value;
      }
      else if (splitter == "userid") {
        value = document.cookie.split(";")[3]

        if (value) {
          value = value.replace(splitter + "=", "");
        }
        return value;
      }
      else if (splitter == "user_type") {

        value = document.cookie.split(";")[4]

        if (value) {
          value = value.replace(splitter + "=", "");
        }

        return value;
      }
    }
    else{
      if (splitter == "username") {
        value = document.cookie.split(";")[0]
  
        if(value){
          value = value.replace(splitter + "=", "");
        }
        return value;
      }
      else if (splitter == "userid") {
        value = document.cookie.split(";")[1]
  
        if(value){
          value = value.replace(splitter + "=", "");
        }
        return value;
      }
      else if (splitter == "user_type") {
        
        value = document.cookie.split(";")[2]
  
        if(value){
          value = value.replace(splitter + "=", "");
        }
  
        return value;
      }
    }


  }
};

export const checkLogin = () => {
  var username = getCookieValue("username")

  if (username) {
    return true
  }
  else {
    return false
  }
}

export const books = [
  {
    "id": "1",
    "key": "unibet",
    "title": "Unibet",
    "logo": ""
  },
  {
    "id": "2",
    "key": "caesars",
    "title": "Caesars",
    "logo": ""
  },
  {
    "id": "3",
    "key": "sugarhouse",
    "title": "SugarHouse",
    "logo": ""
  },
  {
    "id": "4",
    "key": "draftkings",
    "title": "DraftKings",
    "logo": ""
  },
  {
    "id": "5",
    "key": "pointsbetus",
    "title": "PointsBet (US)",
    "logo": ""
  },
  {
    "id": "6",
    "key": "betonlineag",
    "title": "BetOnline.ag",
    "logo": ""
  },
  {
    "id": "7",
    "key": "betmgm",
    "title": "BetMGM",
    "logo": ""
  },
  {
    "id": "8",
    "key": "betrivers",
    "title": "BetRivers",
    "logo": ""
  },
  {
    "id": "9",
    "key": "fanduel",
    "title": "FanDuel",
    "logo": ""
  },
  {
    "id": "10",
    "key": "barstool",
    "title": "Barstool Sportsbook",
    "logo": ""
  },
  {
    "id": "11",
    "key": "bovada",
    "title": "Bovada",
    "logo": ""
  },
  {
    "id": "12",
    "key": "williamhill_us",
    "title": "William Hill (US)",
    "logo": ""
  },
  {
    "id": "13",
    "key": "wynnbet",
    "title": "WynnBET",
    "logo": ""
  },
  // {
  //   "id" : "14",
  //   "key" : "",
  //   "title" : "",
  //   "logo" : ""
  // },
  // {
  //   "id" : "15",
  //   "key" : "",
  //   "title" : "",
  //   "logo" : ""
  // },
  // {
  //   "id" : "",
  //   "key" : "",
  //   "title" : "",
  //   "logo" : ""
  // },
  // {
  //   "id" : "",
  //   "key" : "",
  //   "title" : "",
  //   "logo" : ""
  // },
  // {
  //   "id" : "",
  //   "key" : "",
  //   "title" : "",
  //   "logo" : ""
  // },
]

export const HEADERS = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true'
  },
  
}

