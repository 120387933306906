import React, { useEffect } from 'react'
import Navbar from '../components/global/Navbar'
import { WEBNAME } from '../Consts'
import { sportbooks } from '../Sportbooks'
import './Sportsbook.css'

const Sportsbook = () => {
    useEffect(() => {
        document.title = WEBNAME + " | Our Sportsbooks"

    }, [])
    
    return (
        <div>
            <div>
                <Navbar />
            </div>

            <div className='sportbook-container'>
                <div className="sportbook-title">Our Sportsbooks</div>
                <div className='sportbooks'>
                    {
                        sportbooks.map((sportbook) => (
                            <div className='sportbook' key={sportbook.id}>
                                <img src={sportbook.logo} alt="" />
                                <span>{sportbook.name}</span>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    )
}

export default Sportsbook