import React from 'react'
import './Footer.css'
import visa from "../images/visa.png"
import paypal from "../images/pp.png"
import usdt from "../images/usdt.png"
import btc from "../images/btc.png"
import eth from "../images/eth.png"

const Footer = () => {
  return (
    <div className='footer-container'>
        <h3>We Accept</h3>
        <div className='payments'>
            <div className="payment">
                <img src={visa} alt="" />
            </div>
            <div className="payment paypal">
                <img src={paypal} alt="" />
            </div>
            <div className="payment">
                <img src={usdt} alt="" />
            </div>
            <div className="payment">
                <img src={btc} alt="" />
            </div>
            <div className="payment eth">
                <img src={eth} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Footer