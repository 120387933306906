export const sportbooks = [
    {
        "id": 1,
        "name": "10bet",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2F10bet.jpg&w=96&q=75"

    },
    {
        "id": 2,
        "name": "Betfred",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetfred.jpg&w=96&q=75"

    },
    {
        "id": 3,
        "name": "bet365",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbet365.jpg&w=96&q=75"

    },
    {
        "id": 4,
        "name": "Betstar",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetstar.jpg&w=96&q=75"

    },
    {
        "id": 5,
        "name": "Betsafe",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetsafe.jpg&w=96&q=75"

    },
    {
        "id": 6,
        "name": "Action 24/7",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Faction247.jpg&w=96&q=75"

    },
    {
        "id": 7,
        "name": "Barstool",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbarstool.jpg&w=96&q=75"

    },
    {
        "id": 8,
        "name": "BetNow",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fstorage.googleapis.com%2Foddsjam-static-assets%2Fsportsbook-logos%2Fbetnow.jpg&w=96&q=75"

    },
    {
        "id": 9,
        "name": "Bet Right",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetright.jpg&w=96&q=75"

    },
    {
        "id": 10,
        "name": "BlueBet",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbluebet.jpg&w=96&q=75"

    },
    {
        "id": 11,
        "name": "bwin",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbwin.jpg&w=96&q=75"

    },
    {
        "id": 12,
        "name": "DraftKing",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fdraftkings.jpg&w=96&q=75"

    },
    {
        "id": 13,
        "name": "Coolbet",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fcoolbet.jpg&w=96&q=75"

    },
    {
        "id": 14,
        "name": "Betcris",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetcris.jpg&w=96&q=75"

    },
    {
        "id": 15,
        "name": "Betsson",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetsson.jpg&w=96&q=75"

    },
    {
        "id": 16,
        "name": "Bet America",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetamerica.jpg&w=96&q=75"

    },
    {
        "id": 17,
        "name": "BoomBet",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fboombet.jpg&w=96&q=75"

    },
    {
        "id": 18,
        "name": "FanDuel",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Ffanduel.jpg&w=96&q=75"

    },
    {
        "id": 19,
        "name": "Hard Rock",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fhardrock.jpg&w=96&q=75"

    },
    {
        "id": 20,
        "name": "Wynn BET",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fwynnbet.jpg&w=96&q=75"

    },
    {
        "id": 21,
        "name": "Bet Rivers",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetrivers.jpg&w=96&q=75"

    },
    {
        "id": 22,
        "name": "betMGM",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetmgm.jpg&w=96&q=75"

    },
    {
        "id": 23,
        "name": "Caesars",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fcaesars.jpg&w=96&q=75"

    },
    {
        "id": 24,
        "name": "BarsTool",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbarstool.jpg&w=96&q=75"

    },
    {
        "id": 25,
        "name": "Golden Nugget",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fgoldennugget.jpg&w=96&q=75"

    },
    {
        "id": 26,
        "name": "Points Bet",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fpointsbet.jpg&w=96&q=75"

    },
    {
        "id": 27,
        "name": "Bally Bet",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fballybet.jpg&w=96&q=75"

    },
    {
        "id": 28,
        "name": "Bet Way",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetway.jpg&w=96&q=75"

    },
    {
        "id": 29,
        "name": "SI",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fsi.jpg&w=96&q=75"

    },
    {
        "id": 30,
        "name": "Fox Bet",
        "logo": "https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Ffoxbet.jpg&w=96&q=75"

    },
]