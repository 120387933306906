import React, { useEffect } from 'react'
import Navbar from '../components/global/Navbar'
import Slide from '../components/global/Slide'
import Faq from '../components/home/Faq'
import Live from '../components/live/Live'
import { WEBNAME } from '../Consts'
import HomeBlogs from './HomeBlogs'

const Home = () => {
    useEffect(() => {
        document.title = WEBNAME + " | Home"
        console.log(document.cookie)
    }, [])

    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div>
                <Live />
            </div>

            <div>
                <Faq />
            </div>

            <div style={{"marginTop" : "50px"}}>
                <h3 style={{"color" : "#fff", "fontSize" : "30px", "width" : "100vw", "padding" : "10px", "backgroundColor" : "#25b05d", "fontWeight" : "600"}}>Recent Blogs</h3>
                <HomeBlogs />
            </div>

            <Slide />

        </div>
    )
}

export default Home
