import React from 'react'
import { Navbar } from 'react-bootstrap'

const Calculator = (props) => {
    const three_way = () => {
        document.getElementById('odd3').style.display = "block";
        document.getElementById('stake3').style.display = "block";
        document.getElementById('three_calculate').style.display = "block";
        document.getElementById('two_calculate').style.display = "none";
    }

    const two_way = () => {
        document.getElementById('odd3').style.display = "none";
        document.getElementById('stake3').style.display = "none";
        document.getElementById('three_calculate').style.display = "none";
        document.getElementById('two_calculate').style.display = "block"
    }

    const calculate = () => {
        var odd2 = document.getElementById('odd2').value;
        var stake = document.getElementById('stake').value;
        var odd1 = document.getElementById('odd1').value;
        var odd3 = document.getElementById('odd_3').value;


        var arb1 = (1 / odd1) * 100;
        var arb2 = (1 / odd2) * 100;
        var arb3 = (1 / odd3) * 100;


        //       if (document.getElementById('stake3').style.display='none') {

        var mab = arb1 + arb2;


        var profit = stake / mab * 100;
        var prof = profit - stake;
        var profp = prof / stake * 100;
        var stake1 = (stake * arb1) / mab;
        var stake2 = (stake * arb2) / mab;
        var stake3 = (stake * arb3) / mab;

        var round_stake1 = stake1.toFixed(2);
        var round_profit = profit.toFixed(2);
        var round_prof = prof.toFixed(2);
        var round_profp = profp.toFixed(2);
        var round_stake2 = stake2.toFixed(2);
        var round_stake3 = stake3.toFixed(2);
        document.getElementById('payout').value = round_profit;
        document.getElementById('profit').value = round_prof + '(' + round_profp + '%)';

        document.getElementById('stake2').value = round_stake2;
        document.getElementById('stake1').value = round_stake1;
    }

    const three_calculate = () => {
        var odd2 = document.getElementById('odd2').value;
        var stake = document.getElementById('stake').value;
        var odd1 = document.getElementById('odd1').value;
        var odd3 = document.getElementById('odd_3').value;


        var arb1 = (1 / odd1) * 100;
        var arb2 = (1 / odd2) * 100;
        var arb3 = (1 / odd3) * 100;

        var mab = arb1 + arb2 + arb3;


        var profit = stake / mab * 100;
        var prof = profit - stake;
        var profp = prof / stake * 100;
        var stake1 = (stake * arb1) / mab;
        var stake2 = (stake * arb2) / mab;
        var stake3 = (stake * arb3) / mab;

        var round_stake1 = stake1.toFixed(2);
        var round_profit = profit.toFixed(2);
        var round_prof = prof.toFixed(2);
        var round_profp = profp.toFixed(2);
        var round_stake2 = stake2.toFixed(2);
        var round_stake3 = stake3.toFixed(2);
        document.getElementById('payout').value = round_profit;
        document.getElementById('profit').value = round_prof + '(' + round_profp + '%)';

        document.getElementById('stake2').value = round_stake2;
        document.getElementById('stake1').value = round_stake1;

        document.getElementById('stake_3').value = round_stake3;

    }

    return (
        <div>
            <div class="card shadow mt-10" style={{"margin" : "20px"}}>
                
                <div class="card-header">
                    <strong class="card-title">Arbitrage Calculator</strong>

                </div>
                <div class="btn-group" role="group" aria-label="Basic example" align="Right">
                    <button autofocus type="button" class="btn mb-2 btn-secondary" onClick={two_way}>2-way</button>
                    <button type="button" class="btn mb-2 btn-secondary" onClick={three_way}>3-way</button>

                </div>

                <div class="card-body">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group mb-2">
                                <label for="simpleinput">stake</label>
                                <input type="text" id="stake" class="form-control" />
                            </div>


                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-2">
                                <label for="simpleinput">Bet odd 1</label>
                                <input type="text" id="odd1" class="form-control" value={props.odd1}/>
                            </div>
                            <div class="form-group mb-2">
                                <label for="simpleinput">Bet odd 2</label>
                                <input type="text" id="odd2" class="form-control" value={props.odd2}/>
                            </div>
                            <div class="form-group mb-2" id='odd3' style={{ "display": "none" }}>
                                <label for="simpleinput">Bet odd 3</label>
                                <input type="text" id="odd_3" class="form-control" />
                            </div>


                        </div>
                        <div class="col-md-6">

                            <div class="form-group mb-2">
                                <label for="example-readonly">Stake 1</label>
                                <input type="text" id="stake1" class="form-control" readonly="" value="stake 1" />
                            </div>
                            <div class="form-group mb-2">
                                <label for="example-readonly">Stake 2</label>
                                <input type="text" id="stake2" class="form-control" readonly="" value="stake 1" />
                            </div>



                            <div class="form-group mb-2" id='stake3' style={{ "display": "none" }}>
                                <label for="example-readonly">Stake 3</label>
                                <input type="text" class="form-control" id="stake_3" readonly="" value="stake 3" />
                            </div>

                        </div>
                        <div class="col-md-12">

                            <div class="form-group mb-2">
                                <label for="example-readonly">Payout</label>
                                <input type="text" id="payout" class="form-control" readonly="" value="stake 1" />
                            </div>
                            <div class="form-group mb-2">
                                <label for="example-readonly">Profit <span id="profit_per">(%)</span></label>
                                <input type="text" class="form-control" id="profit" readonly="" value="stake 1" />
                            </div>

                        </div>
                        <button type="button" class="btn mb-2 btn-primary btn-lg btn-block" onClick={calculate} id="two_calculate">CALCULATE</button>

                        <button type="button" class="btn mb-2 btn-primary btn-lg btn-block" onClick={three_calculate} style={{ "display": "none" }} id="three_calculate">CALCULATE</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Calculator