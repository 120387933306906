import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API_URL, HEADERS } from '../../Consts'
import './Faq.css'

const Faq = () => {
    const [questions, setQuestions] = useState([])

    const getQuestions = () => {
        axios.get(API_URL + "get_questions", {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Credentials': 'true'
            }
        }).
            then(res => {
                setQuestions(res.data)
            })
    }

    const postAnswer = (qID) => {
        let answer = window.prompt("Post your Answer", "")

        if (answer == null || answer == "") {
            window.alert("Answer can not be empty string!")
        } else {
            axios.post(API_URL + `post_answer?qID=${qID}`, {
                answer: answer
            }, {
                headers: HEADERS
            }).then((res) => {
                console.log(res)
                if (res.data == "success") {
                    window.alert("Answer posted sucessfully")
                    getQuestions()
                }
                else {
                    window.alert("Error while posting the Answer!")
                }
            })
        }
    }

    useEffect(() => {
        getQuestions()
    }, [])

    const askQuestion = () => {
        let question = window.prompt("Ask your question..", "")

        if (question == null || question == "") {
            window.alert("Question can not be empty string!")
        } else {
            axios.post(API_URL + "post_question", {
                question: question
            }, {
                headers: HEADERS
            }).then((res) => {
                console.log(res)
                if (res.data == "success") {
                    window.alert("Question posted sucessfully")
                    getQuestions()
                }
                else {
                    window.alert("Error while posting the question!")
                }
            })
        }
    }
    return (
        <div className='faq-container'>
            <div className="faq-title">FAQs</div>
            <button className='q-btn' onClick={askQuestion}>Ask a Question</button>
            <div className="qns">
                {
                    questions.map(question => (
                        <div className="qa">
                            <div className="q">{question.question}</div>
                            {
                                question.answers.length < 1 ? <div className='answers'>No answers for this question!</div> :
                                    question.answers.map(answer => (
                                        <div className="answers">
                                            <div className="answer"><p>{answer.answer}</p></div>
                                        </div>
                                    ))
                            }

                            <button className='a-btn' onClick={() => postAnswer(question.question_id)}>Post your Answer</button>

                        </div>

                    ))
                }
            </div>
        </div>
    )
}

export default Faq