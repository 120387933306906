import React, { useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import Navbar from '../components/global/Navbar'
import { checkLogin, getCookieValue, WEBNAME } from '../Consts'
import './Subscribe.css'
import Footer from './Footer'

document.title = WEBNAME + " | Subscriptions"
let stripePromise

const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe("pk_test_51MOiMFFpwYx83mO34bdvbwsRKodSGcQft267fDwbYMMfsfdeQU6CAfoVgqcEAswLAoZNjDB5OKVaIVnsa6Jx8k7w00PkwHJ0PI")
    }

    return stripePromise
}


const Subscribe = () => {


    const redirectToCheckout = async (price) => {
        console.log("redirected")
        if (!checkLogin()) {
            alert("You must have an account to subscribe!")
            window.document.location.href = '/login'
        }
        else {
            var userID = getCookieValue("userid")

            const item = {
                price: price,
                quantity: 1
            }

            const checkoutOptions = {
                lineItems: [item],
                mode: 'payment',
                successUrl: `${window.location.origin}/success?ffhfjjyjfjyjfj=${userID}`,
                cancelUrl: `${window.location.origin}/cancel`
            }

            const stripe = await getStripe()
            const { error } = await stripe.redirectToCheckout(checkoutOptions)
            console.log("error", error)
        }

    }




    return (

        <div>
            <div>
                <Navbar />
            </div>
            <div className='subscribe-container'>
                <div className="subscribe-title">
                    Subscribe Us to enhance your experience
                </div>

                <div className="subscriptions">
                    <div className="subcription">
                        <div className="subcription-title">1 Week</div>
                        <div className="subcription-price">$3</div>
                        <button className="subcription-btn" onClick={() => redirectToCheckout("price_1MOiqrFpwYx83mO3329yklpL")}>Subscribe</button>
                    </div>
                    <div className="subcription">
                        <div className="subcription-title">1 Month</div>
                        <div className="subcription-price">$10</div>
                        <button className="subcription-btn" onClick={() => redirectToCheckout("price_1MOjU8FpwYx83mO35JhjJMfm")}>Subscribe</button>
                    </div>
                    <div className="subcription">
                        <div className="subcription-title">3 Months</div>
                        <div className="subcription-price">$20</div>
                        <button className="subcription-btn" onClick={() => redirectToCheckout("price_1MOjZgFpwYx83mO3ItMmb3OP")}>Subscribe</button>
                    </div>
                    <div className="subcription">
                        <div className="subcription-title">1 Year</div>
                        <div className="subcription-price">$80</div>
                        <button className="subcription-btn" onClick={() => redirectToCheckout("price_1MOjaYFpwYx83mO3q4AYrZSG")}>Subscribe</button>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>


    )
}

export default Subscribe