import React, { useEffect, useState } from "react";
import Navbar from "../../components/global/Navbar";
import "./Login.css";
import logo from "../../images/logo.png";
import axios from "axios";
import { API_URL, HEADERS, WEBNAME } from "../../Consts";

const Login = () => {
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = WEBNAME + " | Login"
  }, [])

  useEffect(() => {
    setErr("");
  }, [pwd, email]);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setLoading(true)
    axios
      .post(`${API_URL}login`, {
        email: email,
        password: pwd,
      }, {
        headers: HEADERS
      })
      .then((res) => {
        console.log(res.data.username);
        console.log(res.data.userid);
        console.log(res.data.status);
        setErr(res.data.status)
        setLoading(false)


        if (res.data.status == "success") {
          document.cookie = `username=${res.data.username}`
          document.cookie = `userid=${res.data.userid}`
          document.cookie = `user_type=${res.data.user_type}`
          document.location.href = "/"
        }
      });
  };
  return (
    <div className="bg">
      <Navbar />
      <div className="login-container">
        <div className="login-form">
          <div className="login-head">
            <h3>LOGIN</h3>
            <img src={logo} alt="" />
          </div>

          <span
            style={
              err == "Wrong Email or Password!"
                ? { color: "crimson", fontSize: "12px", marginBottom: "10px" }
                : { color: "#25B05D", fontSize: "12px", marginBottom: "10px" }
            }
          >
            {err}
          </span>

          <div className="login-inputs">
            <form method="post" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email">EMAIL</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="password">PASSWORD</label>
                <input
                  type="password"
                  name="password"
                  id=""
                  placeholder="Password"
                  onChange={(e) => setPwd(e.target.value)}
                  required
                />
              </div>

              <button className="login-button">{loading ? "Loading..." : "LOGIN"}</button>
            </form>

            <div style={{ fontSize: "10px", marginTop: "10px" }}>
              <span>
                <a href="/forget_password" style={{ "color": "#25b05d" }}>Forget Password?</a>
              </span>
              <span>
                Dont have an account? <a href="/signup" style={{ "color": "yellow" }}>Register</a>{" "}
                here
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
