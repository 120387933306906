import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Navbar from '../components/global/Navbar'
import { API_URL, HEADERS, WEBNAME } from '../Consts'

const ForgetPassword = () => {
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [verify, setVerify] = useState(false);
    const [changePass, setChangePass] = useState(false);
    const [otp, setOTP] = useState("");
    const [userOTP, setUserOTP] = useState("");

    useEffect(() => {
        document.title = WEBNAME + " | Fotgot Password"
    }, [])

    useEffect(() => {
        setErr("")
    }, [email, newPassword, confirmPassword, userOTP])

    const handleVerify = () => {
        setLoading(true)
        if (email) {
            setLoading(true)

            axios.post(API_URL + "forget_password", { email: email }, {
                headers: HEADERS
            }).then(res => {
                setLoading(false)
                setOTP(res.data)
                setVerify(true)
            })
        }
    }

    const changePassword = () => {
        if (otp == userOTP) {
            setChangePass(true)
        }
        else {
            setErr("OTP is incorrect")
        }
    }

    const setNewPass = () => {
        setLoading(true)
        if (newPassword == confirmPassword) {
            axios.post(API_URL + "set_newPassword", { email: email, password: newPassword }, {
                headers: HEADERS
            }).
                then(res => {
                    setLoading(false)
                    if (res.data == "success") {
                        alert("Password Changed Successfully")
                        document.location.href = "/login"
                    }
                    else {
                        setErr("Error while changing the password!")
                    }
                })
        }
        else {
            setErr("Password and Confirm Password did not match!")
        }

    }

    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div className="verify-container">


                {
                    changePass ?
                        <>
                            <h3>Change Your Password</h3><br />
                            <span style={{ "color": "crimson" }}>{err}</span><br />
                            <input type="password" placeholder='New Password' onChange={(e) => setNewPassword(e.target.value)} required style={{"marginBottom" : "20px"}}/><br />
                            <input type="password" placeholder='Confirm Password' onChange={(e) => setConfirmPassword(e.target.value)} required /><br />
                            <button onClick={setNewPass}>{loading ? "Loading..." : "Change the password"}</button><br />
                        </>
                        :

                        !verify ?
                            <>
                                <h3>Change Your Password</h3><br />
                                <input type="email" placeholder='Enter your Email' onChange={(e) => setEmail(e.target.value)} required /><br />
                                <button onClick={handleVerify}>{loading ? "Loading..." : "Get the code"}</button><br />
                            </>
                            :
                            <>
                                <h3>Enter verification code to change your password</h3><br />
                                <span>Your verification code has been sent to {email}</span><br />
                                <span>Please check your spam folder also!</span><br />
                                <small>Do not close this window or tab!</small><br />

                                <div>
                                    <span style={{ "color": "crimson" }}>{err}</span><br />
                                    <label htmlFor="code">Enter your verification code</label><br />
                                    <input type="text" maxLength={6} onChange={(e) => setUserOTP(e.target.value)} /><br />
                                    <button onClick={changePassword}>{loading ? "Verifying..." : "Verify"}</button>
                                </div>
                            </>
                }



            </div>
        </div>

    )
}

export default ForgetPassword