import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { API_KEY, books, timeConverter } from '../../Consts'
import Loader from '../loader/Loader'
import "./Upcoming.css"

const UpcomingEvents = () => {
    const [documentLoading, setDocumentLoading] = useState(true)
    const [sportEvents, setSportEvents] = useState([])

    const [sport, setSport] = useState("")
    const [book, setBook] = useState("all")
    const [sty, setStyle] = useState(true)
    // const [makers, setMakers] = useState([])

    const makers = []

    const currentMakers = []

    const [logo, setLogo] = useState("https://www.freepnglogos.com/uploads/football-png/drawing-football-photo-4.png")

    // const getEventsData = (id) => {
    //     const options = {
    //         method: 'POST',
    //         url: 'https://sportscore1.p.rapidapi.com/events/search',
    //         params: {
    //             sport_id: id,
    //             status: 'notstarted',
    //         },
    //         headers: {
    //             'X-RapidAPI-Key': 'ef99501e80mshb3f87015de9ef70p1f9aacjsn1c3204e970f1',
    //             'X-RapidAPI-Host': 'sportscore1.p.rapidapi.com'
    //         }
    //     };
    //     axios.request(options).then(function (response) {
    //         console.log(response.data);
    //         setFootballEvents(response.data.data)
    //     }).catch(function (error) {
    //         console.error(error);
    //     });
    // }

    // useEffect(() => {
    //     getEventsData(sport)

    //     if (sport == "1") {
    //         setLogo("https://www.freepnglogos.com/uploads/football-png/drawing-football-photo-4.png")
    //     }
    //     else if (sport == "2") {
    //         setLogo("https://www.freepnglogos.com/uploads/tennis-png/ball-tennis-transparent-png-stickpng-2.png")
    //     }
    //     else if (sport == "3") {
    //         setLogo("https://www.freepnglogos.com/uploads/basketball-png/basketball-icon-4.png")
    //     }
    //     else if (sport == "4") {
    //         setLogo("https://www.freepnglogos.com/uploads/skate-png/ice-skate-icon-download-icons-10.png")
    //     }
    //     else if (sport == "5") {
    //         setLogo("https://www.freepnglogos.com/uploads/volleyball-png/benefits-playing-club-volleyball-digs-volleyball-club-10.png")
    //     }
    //     else if (sport == "6") {
    //         setLogo("https://e7.pngegg.com/pngimages/770/167/png-clipart-balloon-handball-template-handball-sport-symmetry-thumbnail.png")
    //     }

    // }, [sport])

    function getRandomItem(arr) {

        // get random index value
        const randomIndex = Math.floor(Math.random() * arr.length);

        // get random item
        const item = arr[randomIndex];

        return item;
    }

    const bestOdds = () => {
        sportEvents.map((event) => {
            event.bookmakers.map((bookmaker) => {
                bookmaker.markets.map(market => {
                    market.outcomes.map((outcome) => {
                        makers.push(outcome.name + outcome.price)
                    })

                })
            })
        })
        setInterval(() => {




            for (let index = 0; index < 30; index++) {

                var idName = getRandomItem(makers)
                currentMakers.push(idName)
                var ele = document.getElementById(idName)
                ele.classList.add("style")
            }



            setInterval(() => {
                console.log(makers)

                var removeid = getRandomItem(currentMakers)
                var remele = document.getElementById(removeid)
                remele.classList.remove("style")

            }, 2000)
        }, 2000)

    }

    // setInterval(() =>
    //     {

    //     },
    //     3000
    // )

    useEffect(() => {
        getEvents(book)


    }, [])

    useEffect(() => {
        getEvents(book)
    }, [book])
    const getEvents = (book) => {
        if (book != "all") {
            setDocumentLoading(true)

            const options = {
                method: 'GET',
                url: `https://api.the-odds-api.com/v4/sports/upcoming/odds/`,
                params: {
                    apiKey: API_KEY,
                    regions: "us",
                    bookmakers: book,
                    markets: "h2h",
                    dateFormat: "unix"
                },
            };

            axios.request(options).then(function (response) {
                // console.log(response.data);
                setSportEvents(response.data)
                setDocumentLoading(true)



            }).catch(function (error) {
                console.error(error);
            });

        }
        else {
            setDocumentLoading(true)
            const options = {
                method: 'GET',
                url: `https://api.the-odds-api.com/v4/sports/upcoming/odds/`,
                params: {
                    apiKey: API_KEY,
                    regions: "us",
                    markets: "h2h",
                    dateFormat: "unix"
                },
            };

            axios.request(options).then(function (response) {
                // console.log(response);
                setSportEvents(response.data)
                setDocumentLoading(false)
            }).catch(function (error) {
                console.error(error);
            });

        }


    }

    useEffect(() => {
        var loadingContainer = document.getElementById("loader-container")

        if(documentLoading){
            loadingContainer.style.display = "block"
        }
        else{
            loadingContainer.style.display = "none"
        }
    }, [documentLoading])

    return (
        <div className='upcoming-container'>
            <Loader/>
            <h3 style={{ "padding": "10px" }}>UPCOMING EVENTS</h3>
            <button onClick={() => bestOdds()} className="btn show-odds">Show Best Odds</button>
            <div className="upcoming-filters">
                <div className="upcoming-book-filter upcoming-filter">
                    <select name="" id="" onChange={(e) => setBook(e.target.value)}>
                        <option value="all" selected>Sportsbooks</option>
                        {
                            books.map(book => (
                                <option value={book.key}>{book.title}</option>
                            ))
                        }
                    </select>
                </div>


            </div>

            <div className="upcoming-events">
                <div className="upcoming-event-table">
                    <div className="upcoming-event-grid">
                        {
                            sportEvents.length < 1 ? <span style={{ 'color': '#fff', 'fontWeight': 'bold' }}>NO EVENTS FOR THIS SPORT</span> :

                                sportEvents.filter(filterEvent => filterEvent.bookmakers.length > 0).map((event) => (
                                    <div className="upcoming-event-card-container">
                                        <div className='upcoming-date-book'>
                                            <div className="upcoming-date"><span>{timeConverter(event.commence_time).date}</span></div>
                                            {book != "all" ? <div className="upcoming-book">Provided by : {event.bookmakers[0] ? event.bookmakers[0].title : ""}</div> : ""}
                                        </div>
                                        <div className="upcoming-event-card">
                                            <div className='upcoming-teams-container'>
                                                <span>{event.home_team}</span>
                                                <span className='vs'>VS</span>
                                                <span>{event.away_team}</span>
                                            </div>
                                            <div className='upcoming-odds-container'>
                                                <div className='x21'>
                                                    <span>1</span>
                                                    <span>X</span>
                                                    <span>2</span>
                                                </div>
                                                {
                                                    book == "all" ?
                                                        event.bookmakers.map((bookmaker) => (
                                                            <div className='odds-outcomes-container'>
                                                                <div className='odds-outcomes'>
                                                                    <span id={bookmaker.markets[0].outcomes[0] ? bookmaker.markets[0].outcomes[0].name + bookmaker.markets[0].outcomes[0].price : "_"}>{bookmaker.markets[0].outcomes[0] ? bookmaker.markets[0].outcomes[0].price : "_"}</span>
                                                                    <span id={bookmaker.markets[0].outcomes[2] ? bookmaker.markets[0].outcomes[2].name + bookmaker.markets[0].outcomes[2].price : "_"}>{bookmaker.markets[0].outcomes[2] ? bookmaker.markets[0].outcomes[2].price : "_"}</span>
                                                                    <span id={bookmaker.markets[0].outcomes[1] ? bookmaker.markets[0].outcomes[1].name + bookmaker.markets[0].outcomes[1].price : "_"}>{bookmaker.markets[0].outcomes[1] ? bookmaker.markets[0].outcomes[1].price : "_"}</span>
                                                                </div>
                                                                <div className="upcoming-book"><span>{bookmaker.title}</span></div>
                                                            </div>

                                                        ))
                                                        :
                                                        <div className='odds-outcomes-single'>
                                                            <span>{event.bookmakers[0] ? event.bookmakers[0].markets[0].outcomes[0].price : "_"}</span>
                                                            <span>{!event.bookmakers[0] ? "_" : !event.bookmakers[0].markets[0] ? "_" : !event.bookmakers[0].markets[0].outcomes[2] ? "_" : event.bookmakers[0].markets[0].outcomes[2].price}</span>
                                                            <span>{event.bookmakers[0] ? event.bookmakers[0].markets[0].outcomes[1].price : "_"}</span>
                                                        </div>
                                                }

                                            </div>
                                        </div>

                                    </div>

                                    // {/* <td>
                                    //     <div className="x12">
                                    //         <span>{event.bookmakers[0].markets[0].outcomes[0].price}</span>
                                    //         <span>{event.bookmakers[0].markets[0].outcomes[2] ? event.bookmakers[0].markets[0].outcomes[2].price : "_"}</span>
                                    //         <span>{event.bookmakers[0].markets[0].outcomes[1].price}</span>
                                    //     </div>
                                    // </td>

                                    // <td className="ou"><span>{event.bookmakers[0].markets[2] ? event.bookmakers[0].markets[2].outcomes[0].price : event.bookmakers[1].markets[2] ? event.bookmakers[1].markets[2].outcomes[0].price : "_"}</span></td>
                                    // <td className="ou"><span>{event.bookmakers[0].markets[2] ? event.bookmakers[0].markets[2].outcomes[1].price : event.bookmakers[1].markets[2] ? event.bookmakers[1].markets[2].outcomes[1].price : "_"}</span></td> */}

                                ))
                        }

                    </div>
                </div>

            </div>

        </div>
    )
}

export default UpcomingEvents
