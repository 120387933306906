import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Loader from '../loader/Loader'
import "./Live.css"
const Live = () => {
    console.log(document.cookie.split(";"))
    const [documentLoading, setDocumentLoading] = useState(true)
    const [footballEvents, setFootballEvents] = useState([])

    const [sport, setSport] = useState("1")
    const [sportbook, setSportbook] = useState("")

    const [logo, setLogo] = useState("https://www.freepnglogos.com/uploads/football-png/drawing-football-photo-4.png")

    const getEventsData = (id) => {
        setDocumentLoading(true)
        const options = {
            method: 'GET',
            url: `https://sportscore1.p.rapidapi.com/sports/${id}/events/live`,
            headers: {
                'X-RapidAPI-Key': 'efa1c23009mshd1ef6a56c804ac1p1f85a9jsn52d88c382aa4',
                'X-RapidAPI-Host': 'sportscore1.p.rapidapi.com'
            }
        };

        axios.request(options).then(function (response) {
            console.log(response);
            setFootballEvents(response.data.data)
            setDocumentLoading(false)
        }).catch(function (error) {
            console.error(error);
        });
    }

    useEffect(() => {
        getEventsData(sport)
    }, [])
    useEffect(() => {
        getEventsData(sport)

        if (sport == "1") {
            setLogo("https://www.freepnglogos.com/uploads/football-png/drawing-football-photo-4.png")
        }
        else if (sport == "2") {
            setLogo("https://www.freepnglogos.com/uploads/tennis-png/ball-tennis-transparent-png-stickpng-2.png")
        }
        else if (sport == "3") {
            setLogo("https://www.freepnglogos.com/uploads/basketball-png/basketball-icon-4.png")
        }
        else if (sport == "4") {
            setLogo("https://www.freepnglogos.com/uploads/skate-png/ice-skate-icon-download-icons-10.png")
        }
        else if (sport == "5") {
            setLogo("https://www.freepnglogos.com/uploads/volleyball-png/benefits-playing-club-volleyball-digs-volleyball-club-10.png")
        }
        else if (sport == "6") {
            setLogo("https://e7.pngegg.com/pngimages/770/167/png-clipart-balloon-handball-template-handball-sport-symmetry-thumbnail.png")
        }

    }, [sport])

    useEffect(() => {
        setDocumentLoading(true)
        let shuffled = footballEvents
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)

        setFootballEvents(shuffled)
        setDocumentLoading(false)

    }, [sportbook])

    useEffect(() => {
        var loadingContainer = document.getElementById("loader-container")

        if(documentLoading && loadingContainer){
            loadingContainer.style.display = "block"
        }
        else if(!documentLoading && loadingContainer){
            loadingContainer.style.display = "none"
        }
    }, [documentLoading])

    return (
        <div className='liveBets-container'>
            <Loader/>
            <h3 style={{ "padding": "10px" }}>LIVE BETS</h3>
            {/* <img src="https://oddsjam.com/_next/image?url=https%3A%2F%2Fcdn.oddsjam.com%2Fsportsbook-logos%2Fbetanysports.jpg&w=96&q=75" alt="" /> */}
            <select name="sportbooks" id="sportbooks" onChange={(e) => setSportbook(e.target.value)}>
                <option value="all">All Sportbooks</option>
                <option value="1">10bet </option>
                <option value="2">Betfred</option>
                <option value="3">bet365</option>
                <option value="4">Betstar</option>
                <option value="5">Betsafe</option>
                <option value="6">Action 24/7</option>
                <option value="7">Barstool</option>
                <option value="8">BetNow</option>
                <option value="9">Bet Right</option>
                <option value="10">BlueBet</option>
                <option value="11">bwin</option>
                <option value="12">DraftKing</option>
                <option value="13">Coolbet</option>
            </select>
            <div className="liveBets-titlebar">
                <div className="liveBets-sports">
                    <div className={sport == "1" ? "liveBets-sport active" : "liveBets-sport"} onClick={() => setSport("1")}>
                        <img src="https://www.freepnglogos.com/uploads/football-png/drawing-football-photo-4.png" alt="" />
                        <span>FOOTBALL</span>
                    </div>
                    <div className={sport == "2" ? "liveBets-sport active" : "liveBets-sport"} onClick={() => setSport("2")}>
                        <img src="https://www.freepnglogos.com/uploads/tennis-png/ball-tennis-transparent-png-stickpng-2.png" alt="" />
                        <span>TENNIS</span>
                    </div>
                    <div className={sport == "3" ? "liveBets-sport active" : "liveBets-sport"} onClick={() => setSport("3")}>
                        <img src="https://www.freepnglogos.com/uploads/basketball-png/basketball-icon-4.png" alt="" />
                        <span>BASKETBALL</span>
                    </div>
                    <div className={sport == "4" ? "liveBets-sport active" : "liveBets-sport"} onClick={() => setSport("4")}>
                        <img src="https://www.freepnglogos.com/uploads/skate-png/ice-skate-icon-download-icons-10.png" alt="" />
                        <span>ICE HOCKEY</span>
                    </div>
                    <div className={sport == "5" ? "liveBets-sport active" : "liveBets-sport"} onClick={() => setSport("5")}>
                        <img src="https://www.freepnglogos.com/uploads/volleyball-png/benefits-playing-club-volleyball-digs-volleyball-club-10.png" alt="" />
                        <span>VOLLEYBALL</span>
                    </div>
                    <div className={sport == "6" ? "liveBets-sport active" : "liveBets-sport"} onClick={() => setSport("6")}>
                        <img style={{ "borderRadius": "50%" }} src="https://e7.pngegg.com/pngimages/770/167/png-clipart-balloon-handball-template-handball-sport-symmetry-thumbnail.png" />
                        <span>HANDBALL</span>
                    </div>
                </div>
            </div>

            {
                footballEvents.length == 0 ? <><br /><span style={{ 'color': '#fff', 'fontWeight': 'bold', "padding": "20px" }}>No Live Events at the moment</span></> :
                    footballEvents.map((event) => (
                        <div className="liveBets-table">
                            <div className="liveBets-table-title">
                                <img src={logo} alt="" />
                                <span>{event.name}</span>
                            </div>

                            <div className="liveBets-table-data">
                                <div className="liveBets-table-dataL">
                                    <div className="liveBets-homeTeam">
                                        <div className='homeTeam-details'>
                                            <img src={event.home_team.logo} />
                                            <span>{event.home_team.name}</span>
                                        </div>


                                        <div className="scores">
                                            <span className='current-score'>{!event.home_score ? "" : event.home_score.current ? event.home_score.current : "_"}</span>
                                            <span>{event.home_score.display ? event.home_score.display : "_"}</span>
                                            <span>{event.home_score.period_1 ? event.home_score.period_1 : "_"}</span>
                                            <span>{event.home_score.period_2 ? event.home_score.period_2 : "_"}</span>
                                            <span>{event.home_score.period_3 ? event.home_score.period_3 : "_"}</span>
                                        </div>
                                    </div>
                                    <div className="liveBets-awayTeam">
                                        <div className='awayTeam-details'>
                                            <img src={event.away_team.logo} />
                                            <span>{event.away_team.name}</span>
                                        </div>


                                        <div className="scores">
                                            <span className='current-score'>{event.away_score.current ? event.away_score.current : "_"}</span>
                                            <span>{event.away_score.display ? event.away_score.display : "_"}</span>
                                            <span>{event.away_score.period_1 ? event.away_score.period_1 : "_"}</span>
                                            <span>{event.away_score.period_2 ? event.away_score.period_2 : "_"}</span>
                                            <span>{event.away_score.period_3 ? event.away_score.period_3 : "_"}</span>
                                        </div>
                                    </div>
                                    <div className='live'>
                                        <i class="fa-solid fa-tv"></i> LIVE
                                    </div>
                                </div>
                                <div className="liveBets-table-dataR">
                                    <div className="liveBets-status">
                                        <div>
                                            <span className='status'>1</span>
                                            <span className='status-data'>{event.main_odds && event.main_odds.outcome_1 ? event.main_odds.outcome_1.value : "_"}</span>
                                        </div>
                                        <div>
                                            <span className='status'>X</span>
                                            <span className='status-data'>{event.main_odds && event.main_odds.outcome_X ? event.main_odds.outcome_X.value : "_"}</span>
                                        </div>
                                        <div >
                                            <span className='status'>2</span>
                                            <span className='status-data'>{event.main_odds && event.main_odds.outcome_2 ? event.main_odds.outcome_2.value : "_"}</span>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div className='line'></div>
                        </div>
                    ))
            }


        </div>
    )
}

export default Live