import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { API_KEY, books, timeConverter } from '../../Consts';
import Loader from '../loader/Loader';
import "./Bets.css"

const Bets = () => {
    const [documentLoading, setDocumentLoading] = useState(true)
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const sport_id = searchParams.get("sport_id");
    const sportTitle = searchParams.get("sport_title");

    const [book, setBook] = useState("all")
    const [sportEvents, setSportEvents] = useState([])

    useEffect(() => {
        
        getEvents(book)
    }, [])


    useEffect(() => {
        console.log(sportEvents.length)
        getEvents(book)

    }, [book])

    const getEvents = (book) => {

        if(book != "all"){
            setDocumentLoading(true)
            const options = {
                method: 'GET',
                url: `https://api.the-odds-api.com/v4/sports/${sport_id}/odds/`,
                params: {
                    apiKey: API_KEY,
                    regions: "us",
                    bookmakers: book,
                    markets: "h2h",
                    dateFormat: "unix"
                },
            };

            axios.request(options).then(function (response) {
                // console.log(response);
                setSportEvents(response.data)
                setDocumentLoading(false)
            }).catch(function (error) {
                console.error(error);
            });
    
        }
        else{
            setDocumentLoading(true)
            const options = {
                method: 'GET',
                url: `https://api.the-odds-api.com/v4/sports/${sport_id}/odds/`,
                params: {
                    apiKey: API_KEY,
                    regions: "us",
                    markets: "h2h",
                    dateFormat: "unix"
                },
            };

            axios.request(options).then(function (response) {
                // console.log(response);
                setSportEvents(response.data)
                setDocumentLoading(false)
            }).catch(function (error) {
                console.error(error);
            });
     
        }
        
        
    }

    useEffect(() => {
        var loadingContainer = document.getElementById("loader-container")

        if(documentLoading){
            loadingContainer.style.display = "block"
        }
        else{
            loadingContainer.style.display = "none"
        }
    }, [documentLoading])

    return (
        <div>
            <Loader/>
            <div className="events">
                <div className="event-table">
                    <div className="filters">
                        <div className="book-filter filter">
                            <select name="" id=""  onChange={(e) => setBook(e.target.value)}>
                                <option value="all" selected>Sportsbooks</option>
                                {
                                    books.map(book => (
                                        <option value={book.key}>{book.title}</option>
                                    ))
                                }
                            </select>
                        </div>
{/* 
                        <div className="date-filter filter">
                            <input type="date" placeholder='Date'/>
                        </div> */}
                    </div>
                    <div className="event-title">
                        <span>{sportTitle}</span>
                    </div>

                    <div className="event-grid">
                        {
                            sportEvents.length < 1 ? <span style={{ 'color': '#fff', 'fontWeight': 'bold' }}>NO EVENTS FOR THIS SPORT</span> :

                                sportEvents.map((event) => (
                                    <div className="event-card-container">
                                        <div className='date-book'>
                                            <div className="date"><span>{timeConverter(event.commence_time).date}</span></div>
                                            <div className="book">Provided by : {event.bookmakers[0] ? event.bookmakers[0].title : ""}</div>
                                        </div>
                                        <div className="event-card">
                                            <div className='teams-container'>
                                                <span>{event.home_team}</span>
                                                <span className='vs'>VS</span>
                                                <span>{event.away_team}</span>
                                            </div>
                                            <div className='odds-container'>
                                                <div>
                                                    <span>1</span>
                                                    <span>X</span>
                                                    <span>2</span>
                                                </div>
                                                <div>
                                                    <span>{!event.bookmakers[0] ? "_" : !event.bookmakers[0].markets[0] ? "_" : !event.bookmakers[0].markets[0].outcomes[2] ? "_" : event.bookmakers[0].markets[0].outcomes[0].price}</span>
                                                    <span>{!event.bookmakers[0] ? "_" : !event.bookmakers[0].markets[0] ? "_" : !event.bookmakers[0].markets[0].outcomes[2] ? "_" : event.bookmakers[0].markets[0].outcomes[2].price}</span>
                                                    <span>{!event.bookmakers[0] ? "_" : !event.bookmakers[0].markets[0] ? "_" : !event.bookmakers[0].markets[0].outcomes[2] ? "_" : event.bookmakers[0].markets[0].outcomes[1].price}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    // {/* <td>
                                    //     <div className="x12">
                                    //         <span>{event.bookmakers[0].markets[0].outcomes[0].price}</span>
                                    //         <span>{event.bookmakers[0].markets[0].outcomes[2] ? event.bookmakers[0].markets[0].outcomes[2].price : "_"}</span>
                                    //         <span>{event.bookmakers[0].markets[0].outcomes[1].price}</span>
                                    //     </div>
                                    // </td>

                                    // <td className="ou"><span>{event.bookmakers[0].markets[2] ? event.bookmakers[0].markets[2].outcomes[0].price : event.bookmakers[1].markets[2] ? event.bookmakers[1].markets[2].outcomes[0].price : "_"}</span></td>
                                    // <td className="ou"><span>{event.bookmakers[0].markets[2] ? event.bookmakers[0].markets[2].outcomes[1].price : event.bookmakers[1].markets[2] ? event.bookmakers[1].markets[2].outcomes[1].price : "_"}</span></td> */}

                                ))
                        }

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Bets