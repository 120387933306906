import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/global/Navbar";
import { API_URL, HEADERS, WEBNAME } from "../../Consts";
import logo from "../../images/logo.png";
import "./Signup.css";

const Signup = () => {
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [verify, setVerify] = useState(false);
  const [otp, setOTP] = useState("");
  const [userOTP, setUserOTP] = useState("");

  useEffect(() => {
    setErr("")
  }, [otp])

  useEffect(() => {
    document.title = WEBNAME + " | Sign Up"
  }, [])

  useEffect(() => {
    if(err == "success"){
      window.document.location.href = "/login"
    }
  }, [err])

  useEffect(() => {
    setErr("")
  }, [username, pwd, email])

  const verifySignup = (e) => {
    setLoading(true)
    e.preventDefault()
    axios
    .post(`${API_URL}check_email`,{
      username: username,
      email: email,
      password: pwd,
    }, {
      headers: HEADERS
    })
    .then((res) => {
      setLoading(false)
      if(res.data == "Email already used! Please try again with different email"){
        setErr(res.data);
      }
      else{
        setOTP(res.data)
        setVerify(true)
      }
      setLoading(false)
    });

  } 

  const handleSubmit = (e) => {
    if(userOTP == otp){
      e.preventDefault()
      setLoading(true)
      axios
        .post(`${API_URL}register`,{
          username: username,
          email: email,
          password: pwd,
        }, {
          headers: HEADERS
        })
        .then((res) => {
          setErr(res.data);
          console.log(res.data);
          setLoading(false)
        });
    }
    else{
      setErr("Verification code is incorrect!")
    }
    
  };
  return (
    <div className="bg">
      <Navbar />
      {
        verify ?
          <div className="verify-container">
            <h3>Verify your account</h3>
            <span>Your verification code has been sent to {email}</span><br />
            <span>Please check your spam folder also!</span><br />
            <small>Do not close this window or tab!</small><br />

            <div>
              <span style={{"color" : "crimson"}}>{err}</span><br />
              <label htmlFor="code">Enter your verification code</label><br />
              <input type="text" maxLength={6} onChange={(e) => setUserOTP(e.target.value)}/><br />
              <button onClick={handleSubmit}>{loading ? "Verifying..." : "Verify"}</button>
            </div>
          </div>
          :
          <div className="signup-container">
            <div className="signup-form">
              <div className="signup-head">

                <h3>SIGNUP</h3>
                <img src={logo} alt="" />
              </div>

              <span
                style={
                  err ==
                    "Email already used! Please try again with different email"
                    ? { color: "crimson", "fontSize": "12px", "marginBottom": "10px" }
                    : { color: "#25B05D", "fontSize": "12px", "marginBottom": "10px" }
                }
              >
                {err}
              </span>

              <div className="signup-inputs">
                <form onSubmit={verifySignup} method='post'>
                  <div>
                    <label htmlFor="username">USERNAME</label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Username"
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="email">EMAIL</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="password">PASSWORD</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      onChange={(e) => setPwd(e.target.value)}
                      required
                    />
                  </div>

                  <button className="signup-button">
                    {loading ? "Loading..." : "SIGNUP"}
                  </button>
                </form>

                <div style={{ fontSize: "10px", marginTop: "10px" }}>
                  <span>
                    Already have an account? <a href="/login" style={{ "color": "yellow" }}>Login</a> here
                  </span>
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
};

export default Signup;
