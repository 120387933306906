import React, { useEffect, useState } from 'react'
import Navbar from '../../components/global/Navbar'
import './ArbitragePrematch.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { API_URL, getCookieValue, HEADERS, WEBNAME } from '../../Consts'
import Calculator from '../../components/calculator/Calculator'
import Loader from '../../components/loader/Loader'

const ArbitragePrematch = () => {
    const [documentLoading, setDocumentLoading] = useState(true)
    const [arbitragePrematches, setArbitragePreMatches] = useState([])
    const [sportsBooks, setSportsBooks] = useState([])
    const [showFilters, setShowFilters] = useState(false)
    const [showOneMatch, setShowOneMatch] = useState(false)
    const [showCalculator, setShowCalculator] = useState(false)
    const [subscribed, setSubscribed] = useState(false)
    const [loading, setLoading] = useState(false)
    const [odd1, setOdd1] = useState("")
    const [odd2, setOdd2] = useState("")
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(0)
    const [filteredBooks, setFilteredBooks] = useState([])

    const [singleGameData, setSingleGameData] = useState([])

    useEffect(() => {
        document.title = WEBNAME + " | Arbitrage Prematches"
        var userid = getCookieValue("userid")

        if (userid) {
            userid = userid.replace(" ", "")

            axios.post(API_URL + "check_subscribed", { userid: userid }, {
                headers: HEADERS
            }).
                then(res => {
                    console.log(res.data)
                    setSubscribed(res.data)
                })
        }

    }, [])

    const getOneGameData = (gameID) => {
        setDocumentLoading(true)
        axios.get(API_URL + `single_game_data?game_id=${gameID}`, {
            headers: HEADERS
        }).
            then(res => {
                setDocumentLoading(false)
                console.log(res)
                setSingleGameData(res.data)
            })

        setShowOneMatch(true)
    }

    const handleCalculator = (odd_1, odd_2) => {
        setOdd1(odd_1)
        setOdd2(odd_2)
        setShowCalculator(true)
    }

    const get_arbitragePrematch = () => {
        setDocumentLoading(true)
        axios.get(API_URL + "arbitrage-prematch", {
            headers: HEADERS
        }).
            then(res => {
                setDocumentLoading(false)
                console.log(res.data.length)
                setArbitragePreMatches(res.data)
            })
    }
    // console.log(API_URL + `get_by_sportsbooks?sportsbooks=${filteredBooks}`)

    const get_sportsBooks = () => {
        axios.get(API_URL + "sportsbooks", {
            headers: HEADERS
        }).
            then(res => {
                // console.log(res.data)
                setSportsBooks(res.data)
                setFilteredBooks(res.data)

            })
    }

    const get_filtered_data = () => {
        setMin(parseFloat(min))
        setMax(parseFloat(max))
        setLoading(true)
        axios.get(API_URL + `get_by_sportsbooks?sportsbooks=${filteredBooks}&min=${min}&max=${max}`, {
            headers: HEADERS
        }).
            then(res => {
                console.log("filtered: ", res.data.length)
                setLoading(false)
                setArbitragePreMatches(res.data)
                setShowOneMatch(false)
                setShowFilters(false)
            })
    }

    const filterBySportsbook = (sportBook) => {
        if (sportBook != "") {
            axios.get(API_URL + `get_by_sportsbooks?sportsbook=${sportBook}`, {
                headers: HEADERS
            }).
                then(res => {
                    console.log(res.data)
                    setArbitragePreMatches(res.data)
                })
        }
    }

    const resetFilters = () => {
        get_arbitragePrematch()
        get_sportsBooks()
        setShowFilters(false)

    }

    const filterSportbooks = (value) => {
        if (filteredBooks.includes(value)) {
            console.log("if")
            setFilteredBooks(filteredBooks.filter(filteredBook => filteredBook !== value))
        }
        else {
            console.log("else")
            setFilteredBooks([...filteredBooks, value])
        }

    }

    useEffect(() => {
        get_arbitragePrematch()
        get_sportsBooks()
        console.log(arbitragePrematches)
    }, [])

    useEffect(() => {
        var loadingContainer = document.getElementById("loader-container")

        if (documentLoading) {
            loadingContainer.style.display = "block"
        }
        else {
            loadingContainer.style.display = "none"
        }
    }, [documentLoading])
    return (
        <div>
            <div>
                <Navbar />
            </div>

            <Loader />

            {
                showFilters ?

                    <div className="arbitrage-filters-container">
                        <span className='close-btn' onClick={() => setShowFilters(!showFilters)}>X</span>
                        <br />
                        <br />
                        <div className='arbitrage-filters'>
                            <div>
                                <div className="filter profit">
                                    <span><b>Profit Range</b></span>

                                    {
                                        min == 0 || max == 0 ?
                                            <div>
                                                <input type="number" id='min' placeholder='Min' onChange={(e) => setMin(e.target.value)} />
                                                <input type="number" id='max' placeholder='Max' onChange={(e) => setMax(e.target.value)} />
                                            </div>
                                            :

                                            <div>
                                                <input type="number" id='min' placeholder='Min' onChange={(e) => setMin(e.target.value)} defaultValue={min}/>
                                                <input type="number" id='max' placeholder='Max' onChange={(e) => setMax(e.target.value)} defaultValue={max}/>
                                            </div>
                                    }


                                </div>
                            </div>

                            <div>
                                <span><b>Bookmakers</b></span>
                                <br />
                                <div className="filter bookmakers">
                                    {
                                        sportsBooks.length > 0 ?
                                            sportsBooks.map(sportsbook => (
                                                <div className="bookmaker">
                                                    {
                                                        filteredBooks.includes(sportsbook) ?

                                                            <input type="checkbox" name={sportsbook} id={sportsbook} value={sportsbook} onChange={(e) => filterSportbooks(e.target.value)} defaultChecked />

                                                            :

                                                            <input type="checkbox" name={sportsbook} id={sportsbook} value={sportsbook} onChange={(e) => filterSportbooks(e.target.value)} />

                                                    }

                                                    <span>{sportsbook}</span>

                                                </div>
                                            ))
                                            : ""
                                    }

                                </div>
                            </div>

                            <div className="apply-btn">
                                <button onClick={get_filtered_data}>{loading ? "Applying Filters....." : "Apply Filters"}</button>
                            </div>

                        </div>

                    </div>

                    : ""

            }

            {
                showCalculator ?
                    <div style={{ "marginTop": "30px" }}>
                        <span style={{ "cursor": "pointer", "color": "#fff", "fontSize": "20px", "fontWeight": "bold", "padding": "10px" }} onClick={() => setShowCalculator(false)}>X</span>
                        <Calculator odd1={odd1} odd2={odd2} />
                    </div>

                    :
                    showOneMatch ?
                        <div className='prematch-container'>
                            <span onClick={() => setShowOneMatch(false)} style={{ "fontSize": "20px", "fontWeight": "bold", "cursor": "pointer" }}>X</span>
                            {
                                singleGameData.length < 1 ? <span>No Data to show</span> :

                                    singleGameData.map((arbitragePrematch) => (

                                        <div>

                                            <br />
                                            <div className="table-line"></div>
                                            <table className='arbitrage-table'>
                                                <thead>
                                                    <td className='head-1'>
                                                        <div>
                                                            <h3>{arbitragePrematch.rate}</h3>
                                                            <span>{arbitragePrematch.time}</span>
                                                        </div>
                                                    </td>

                                                    <td className='head-2'>
                                                        <div>
                                                            <h3>{arbitragePrematch.sport}</h3>
                                                            <span>{arbitragePrematch.date}</span>
                                                        </div>
                                                    </td>

                                                    <td className='head-3'>
                                                        <div>
                                                            <FontAwesomeIcon icon={faCalculator} style={{ "cursor": "pointer" }} onClick={() => handleCalculator(arbitragePrematch.odd_1, arbitragePrematch.odd_2)} />
                                                        </div>
                                                    </td>
                                                </thead>

                                                <br />
                                                <tbody>
                                                    <tr>
                                                        <td>{arbitragePrematch.bookie_1}</td>
                                                        <td>
                                                            <div className='arbitrage-team'>
                                                                <span>{arbitragePrematch.teams_1}</span><br />
                                                                <small>{arbitragePrematch.league_1}</small>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className='arbitrage-calc'>
                                                                <span>{arbitragePrematch.outcome_1}</span>
                                                                <span>{arbitragePrematch.odd_1}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <br />
                                                    <tr>
                                                        <td>{arbitragePrematch.bookie_2}</td>
                                                        <td>
                                                            <div className='arbitrage-team'>
                                                                <span>{arbitragePrematch.teams_2}</span><br />
                                                                <small>{arbitragePrematch.league_2}</small>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className='arbitrage-calc'>
                                                                <span>{arbitragePrematch.outcome_2}</span>
                                                                <span>{arbitragePrematch.odd_2}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ))
                            }

                        </div>
                        :
                        showCalculator ?
                            <div style={{ "marginTop": "30px" }}>
                                <span style={{ "cursor": "pointer", "color": "#fff", "fontSize": "20px", "fontWeight": "bold", "padding": "10px" }} onClick={() => setShowCalculator(false)}>X</span>
                                <Calculator odd1={odd1} odd2={odd2} />
                            </div>
                            :
                            <>
                                <div className="filter-btns">
                                    <button className="filter-btn" onClick={() => setShowFilters(!showFilters)}>{!showFilters ? "Show Filters" : "Hide Filters"}</button>
                                    <button className="reset-btn" onClick={resetFilters}>Reset all filters</button>
                                </div>


                                <div className='prematch-container'>
                                    {
                                        arbitragePrematches.length < 1 ? <span>No Data to show</span> :
                                            !subscribed ?
                                                <>

                                                    {arbitragePrematches.slice(0, 2).map((arbitragePrematch) => (

                                                        <div>

                                                            <br />
                                                            <div className="table-line"></div>
                                                            <table className='arbitrage-table'>
                                                                <thead>
                                                                    <td className='head-1'>
                                                                        <div>
                                                                            <h3>{arbitragePrematch.rate}</h3>
                                                                            <span>{arbitragePrematch.time}</span>
                                                                        </div>
                                                                    </td>

                                                                    <td className='head-2'>
                                                                        <div>
                                                                            <h3>{arbitragePrematch.sport}</h3>
                                                                            <span>{arbitragePrematch.date}</span>
                                                                        </div>
                                                                    </td>

                                                                    <td className='head-3'>
                                                                        <div>
                                                                            <FontAwesomeIcon icon={faCalculator} style={{ "cursor": "pointer" }} onClick={() => handleCalculator(arbitragePrematch.odd_1, arbitragePrematch.odd_2)} />
                                                                        </div>
                                                                    </td>
                                                                </thead>

                                                                <br />
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{arbitragePrematch.bookie_1}</td>
                                                                        <td>
                                                                            <div className='arbitrage-team'>
                                                                                <span>{arbitragePrematch.teams_1}</span><br />
                                                                                <small>{arbitragePrematch.league_1}</small>
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div className='arbitrage-calc'>
                                                                                <span>{arbitragePrematch.outcome_1}</span>
                                                                                <span>{arbitragePrematch.odd_1}</span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <br />
                                                                    <tr>
                                                                        <td>{arbitragePrematch.bookie_2}</td>
                                                                        <td>
                                                                            <div className='arbitrage-team'>
                                                                                <span>{arbitragePrematch.teams_2}</span><br />
                                                                                <small>{arbitragePrematch.league_2}</small>
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div className='arbitrage-calc'>
                                                                                <span>{arbitragePrematch.outcome_2}</span>
                                                                                <span>{arbitragePrematch.odd_2}</span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ "fontSize": "12px", "color": "aqua", "textDecoration": "underline", "marginTop": "20px", "cursor": "pointer" }} onClick={() => getOneGameData(arbitragePrematch.game_id)}>Show More</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ))}
                                                    <div style={{ "marginTop": "50px", "marginBottom": "50px" }}>
                                                        <span style={{ "color": "#fff", "fontSize": "18px" }}><a href="/subscriptions">Subscribe</a> to see more results*</span>
                                                    </div>
                                                </>
                                                :

                                                arbitragePrematches.map((arbitragePrematch) => (

                                                    <div>

                                                        <br />
                                                        <div className="table-line"></div>
                                                        <table className='arbitrage-table'>
                                                            <thead>
                                                                <td className='head-1'>
                                                                    <div>
                                                                        <h3>{arbitragePrematch.rate}</h3>
                                                                        <span>{arbitragePrematch.time}</span>
                                                                    </div>
                                                                </td>

                                                                <td className='head-2'>
                                                                    <div>
                                                                        <h3>{arbitragePrematch.sport}</h3>
                                                                        <span>{arbitragePrematch.date}</span>
                                                                    </div>
                                                                </td>

                                                                <td className='head-3'>
                                                                    <div>
                                                                        <FontAwesomeIcon icon={faCalculator} style={{ "cursor": "pointer" }} onClick={() => handleCalculator(arbitragePrematch.odd_1, arbitragePrematch.odd_2)} />
                                                                    </div>
                                                                </td>
                                                            </thead>

                                                            <br />
                                                            <tbody>
                                                                <tr>
                                                                    <td>{arbitragePrematch.bookie_1}</td>
                                                                    <td>
                                                                        <div className='arbitrage-team'>
                                                                            <span>{arbitragePrematch.teams_1}</span><br />
                                                                            <small>{arbitragePrematch.league_1}</small>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div className='arbitrage-calc'>
                                                                            <span>{arbitragePrematch.outcome_1}</span>
                                                                            <span>{arbitragePrematch.odd_1}</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <br />
                                                                <tr>
                                                                    <td>{arbitragePrematch.bookie_2}</td>
                                                                    <td>
                                                                        <div className='arbitrage-team'>
                                                                            <span>{arbitragePrematch.teams_2}</span><br />
                                                                            <small>{arbitragePrematch.league_2}</small>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div className='arbitrage-calc'>
                                                                            <span>{arbitragePrematch.outcome_2}</span>
                                                                            <span>{arbitragePrematch.odd_2}</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ "fontSize": "12px", "color": "aqua", "textDecoration": "underline", "marginTop": "20px", "cursor": "pointer" }} onClick={() => getOneGameData(arbitragePrematch.game_id)}>Show More</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ))


                                    }



                                </div>
                            </>
            }
        </div>
    )
}

export default ArbitragePrematch