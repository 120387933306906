import React, { useEffect } from 'react'
import Navbar from '../components/global/Navbar'
import UpcomingEvents from '../components/home/UpcomingEvents'
import { WEBNAME } from '../Consts'

const Upcoming = () => {
  useEffect(() => {
    document.title = WEBNAME + " | Upcoming Events"

  }, [])

  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div>
        <UpcomingEvents />
      </div>
    </div>
  )
}

export default Upcoming