import React from 'react'
import './Loader.css'
const Loader = () => {
    return (

        <div className='loader-container' id='loader-container'>
            <div className="loader"></div>
        </div>

    )
}

export default Loader