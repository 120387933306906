import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Navbar from '../components/global/Navbar'
import Loader from '../components/loader/Loader'
import { API_URL, checkLogin, getCookieValue, HEADERS, WEBNAME } from '../Consts'
import { faComment, faFolder, faUser } from '@fortawesome/free-solid-svg-icons'
import './Blogs.css'
const Blogs = () => {
    const [documentLoading, setDocumentLoading] = useState(true)
    const [showAddBlog, setShowAddBlog] = useState(false)
    const [showComments, setShowComments] = useState(false)
    const [blogs, setBlogs] = useState([])
    const [comments, setComments] = useState([])
    const [comment, setComment] = useState([])
    const [blogTitle, setBlogTitle] = useState("")
    const [blogDescription, setBlogDescription] = useState("")
    const [blogImage, setBlogImage] = useState("")

    const [showBlog, setShowBlog] = useState(false)

    const [blogData, setBlogData] = useState({})

    const [err, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)

    const fileChangeHandler = (e) => {
        setSelectedFile(e.target.files[0])
        console.log(e.target.files[0])
    }

    const uploadPost = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append(
            "file",
            selectedFile,
            selectedFile.name
        )

        console.log(formData)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: HEADERS
        }

        // fetch(API_URL + "upload_image", requestOptions).
        // then(response => {
        //     response.json()
        //     console.log(response.json())
        // })

        axios
            .post(`${API_URL}upload_image`, formData, {
                headers: HEADERS
            })
            .then((res) => {
                console.log(res)
            });
    }

    const handleAdmin = () => {

        var userType = getCookieValue("user_type")
        console.log("User :", userType)
        if (userType == " admin") {
            return true
        }
        else {
            return false
        }

    }

    useEffect(() => {
        var adminStatus = handleAdmin()
        setAdmin(adminStatus)
        console.log(adminStatus)
        document.title = WEBNAME + " | Blogs"
        getBlogs()

    }, [])

    const getBlogs = () => {
        setDocumentLoading(true)
        axios.get(
            API_URL + "get_blogs",
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': '*',
                    'Access-Control-Allow-Credentials': 'true'
                }
            }
        ).
            then(res => {
                setDocumentLoading(false)
                console.log(res.data)
                setBlogs(res.data)
            })
    }

    const deleteBlog = (blogid) => {
        if(window.confirm("Permenantly delete this post?")){
            axios.delete(API_URL + `delete_blog?blogid=${blogid}`, {
                headers: HEADERS
            }).
                then(res => {
                    if (res.data == "success") {
                        alert("Blog Post Deleted Successfully!")
                        getBlogs()
                    }
                    else {
                        alert("Failed to delete the Post!")
                        getBlogs()
                    }
                })
        }
    }

    const postBlog = (e) => {
        e.preventDefault()
        if (blogTitle == "" || blogDescription == "") {
            setError("Blog Title or Blog Description can not be Empty!")
        }
        else {
            setLoading(true)
            axios
                .post(`${API_URL}post_blog`, {
                    title: blogTitle,
                    description: blogDescription,
                    image: blogImage
                }, {
                    headers: HEADERS
                })
                .then((res) => {
                    if (res.data == 'success') {
                        setLoading(false)
                        alert("Blog Posted Successfully")
                        getBlogs()
                        setShowAddBlog(false)
                    }
                    else {
                        setError("Error while posting the blog!")
                    }
                });
        }

    }

    const singleBlog = (blog_id, blog_title, blog_description, blog_dop, blog_image) => {
        setBlogData({
            blog_id: blog_id,
            blog_title: blog_title,
            blog_description: blog_description,
            blog_dop: blog_dop,
            blog_image: blog_image
        })

        setShowBlog(true)
    }

    const handleComments = (blog_id) => {
        if (!checkLogin()) {
            alert("You must have an account to post comments!")
            window.document.location.href = '/login'
        }
        else {
            if (comment != "") {
                var userid = getCookieValue("userid")
                var username = getCookieValue("username")
                if (comment != null || comment != "") {
                    axios.post(`${API_URL}post_comment`, { userid: userid, comment: comment, blogid: blog_id, username: username }, {
                        headers: HEADERS
                    }).
                        then(res => {
                            setLoading(true)
                            console.log(res)
                            if (res.data == "success") {
                                setLoading(false)
                                alert("Comment posted successfully")
                                handleShowComments()
                            }
                            else {
                                alert("An error occured while posting the comment")
                            }
                        })
                }
            }
            else {
                alert("Comment can not be an empty string!")
            }


        }
    }

    const handleShowComments = () => {
        axios.get(`${API_URL}get_comments?blogid=${blogData.blog_id}`, {
            headers: HEADERS
        }).
            then(res => {
                console.log(res)
                setComments(res.data)
                setShowComments(true)
            })
    }

    const getNoOfComments = (blogid) => {
        var count = 1
        axios.get(API_URL + `getnoofcomments?blogid=${blogid}`, {
            headers: HEADERS
        }).
            then(res => {
                console.log(res.data)
                count = parseInt(res.data)

            })

        return count


    }

    const deleteComment = (commentid) => {
        if (window.confirm("Do you want to delete this comment?")) {
            axios.delete(`${API_URL}delete_comment?commentid=${commentid}`, {
                headers: HEADERS
            }).
                then(res => {
                    if (res.data == "success") {
                        alert("comment deleted succesfully")
                        handleShowComments()
                    }
                    else {
                        alert("An error while deleting the the comment")
                    }
                })
        }

    }

    const editComment = (commentid, comment) => {
        var newComment = window.prompt("Edit the comment", comment)

        if (newComment != comment) {
            axios.put(`${API_URL}edit_comment?commentid=${commentid}`, { newComment: newComment }, {
                headers: HEADERS
            }).
                then(res => {
                    if (res.data == "success") {
                        alert("comment updated succesfully")
                        handleShowComments()
                    }
                    else {
                        alert("An error while updating the the comment")
                    }
                })
        }

    }

    useEffect(() => {
        var loadingContainer = document.getElementById("loader-container")

        if (documentLoading) {
            loadingContainer.style.display = "block"
        }
        else {
            loadingContainer.style.display = "none"
        }
    }, [documentLoading])

    return (
        <div>
            <div>
                <Navbar />
            </div>

            <Loader />

            {
                showBlog ?
                    <div className='single-blog'>
                        <h3 className='x-mark' onClick={() => setShowBlog(!showBlog)}>X</h3>
                        <div className="single-blog-img">
                            <img src={blogData.blog_image} alt="" />
                        </div>

                        <div className="single-blog-title">
                            <h3>{blogData.blog_title}</h3>
                            <span>{blogData.blog_dop}</span>
                            <button className='comment-btn' onClick={handleShowComments}>Show Comments</button>
                        </div>

                        <div className="single-blog-description">
                            <p>{blogData.blog_description}</p>
                        </div>
                    </div>
                    : ""

            }

            {
                showComments ?
                    <div className='comments-container'>
                        <span onClick={() => setShowComments(false)} className="x-mark">X</span>
                        <div className="comments">
                            {
                                comments.length < 1 ? <span>No Comments for this post</span> :
                                    comments.map(comment => (
                                        <div className="comment">
                                            <span>Posted by {comment.username}</span>
                                            <p>{comment.comment}</p>

                                            {
                                                admin ?
                                                    <div className="admin-actions">
                                                        <button onClick={() => editComment(comment.commentid, comment.comment)}>Edit</button>
                                                        <button onClick={() => deleteComment(comment.commentid)}>Delete</button>
                                                    </div>
                                                    : ""
                                            }

                                        </div>
                                    ))
                            }

                            <div className='add-comment'>
                                <label htmlFor="add-comment">Add your comment</label>
                                <input name="add-comment" id="add-comment" required onChange={(e) => setComment(e.target.value)} />
                                <button onClick={() => handleComments(blogData.blog_id)}>{loading ? "Posting...." : "Post"}</button>
                            </div>

                        </div>
                    </div>
                    :
                    ""
            }


            {
                showAddBlog ?

                    <div className='add-new-blog'>
                        <span onClick={() => setShowAddBlog(!showAddBlog)}>X</span><br /><br />
                        <p>{err}</p>
                        <form className='form' id='myForm' encType='multipart/form-data'>
                            <input type="text" placeholder='Blog Title' onChange={(e) => setBlogTitle(e.target.value)} />
                            <input type="text" name="" id="" placeholder='Image Link' onChange={(e) => setBlogImage(e.target.value)} />
                            {/* <fieldset>
                                <input type="file" id="inpFile" name='image' accept='.jpeg, .png, .jpg' onChange={fileChangeHandler} />
                            </fieldset> */}
                            <textarea name="" id="" cols="30" rows="10" placeholder='Blog Description...' onChange={(e) => setBlogDescription(e.target.value)}></textarea>
                            <button type='submit' onClick={postBlog}>{loading ? "Loading..." : "Post"}</button>
                        </form>

                    </div>
                    : ""
            }

            {
                admin ? <button className='add-blog' onClick={() => setShowAddBlog(!showAddBlog)}>Add a Blog Post</button> : ""
            }


            <div>
                <div className="blogs-container">
                    {
                        blogs.length < 1 ? <span className='noBlogs'>No Blogs to Show</span> :
                            blogs.map(blog => (
                                <div className="blog">
                                    <div className='blog-img'>
                                        <img src={blog.image} alt="" />

                                        <div className='blog-overlay-details'>
                                            <h2>{blog.blog_title}</h2>

                                            <div className='blog-detail-icons'>
                                                <span><FontAwesomeIcon icon={faUser} className="icon" />Admin</span>
                                                <span><FontAwesomeIcon icon={faComment} className="icon" />{getNoOfComments(blog.blogid)}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="blog-details">
                                        <p>{blog.blog_description.length > 100 ? blog.blog_description.slice(0, 100) + "..." : blog.blog_description}</p>
                                        <span>{blog.dop}</span><br /><br />
                                        <div className='blog-details-buttons'>
                                            <button onClick={() => singleBlog(blog.blogid, blog.blog_title, blog.blog_description, blog.dop, blog.image)}>Read More</button>
                                            {/* <button onClick={() => setAdmin(!admin)}>Click</button> */}

                                            {
                                                admin ? <button className='delete' onClick={() => deleteBlog(blog.blogid)}>Delete</button> : ""
                                            }

                                        </div>
                                    </div>

                                </div>
                            ))
                    }


                </div>
                <div className="row" style={{ "marginTop": "50px", "marginBottom": "50px" }}>
                    <div className="col-12">
                        <nav aria-label="Page navigation">
                            <ul className="pagination justify-content-center mb-0">
                                <li className="page-item disabled">
                                    <a className="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </li>
                                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                <li className="page-item">
                                    <a className="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Blogs