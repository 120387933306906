import axios from 'axios';
import React from 'react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { API_URL, getCookieValue, WEBNAME } from '../Consts';

const Success = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get("ffhfjjyjfjyjfj")

  useEffect(() => {
    document.title = WEBNAME + " | Payement Successfull"

    if(param){
      var userid = getCookieValue("userid").replace(" ", "")

      axios.post(API_URL + "subscribed", {userid: userid}).
      then(res => {
        console.log(res)
      })
    }
    else{
      console.log("Malicious request")
    }
  }, [])
  return (
    <div style={{ "textAlign": "center", "marginTop": "100px" }}>
      <span style={{ "color": "#25b05d", "fontSize": "20px" }}>Your payment was successful!</span>
      <br />
      <span style={{ "color": "#fff", "fontSize": "15px" }}>Thanks For Subscribing Us..</span>
      <a href="/">Go back to Home</a>
    </div>
  )
}

export default Success