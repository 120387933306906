import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import frame1 from "../../images/frame1.jpg";
import frame2 from "../../images/frame2.png";
import frame3 from "../../images/frame3.png";
import frame4 from "../../images/frame4.png";
import frame5 from "../../images/frame5.png";
import frame6 from "../../images/frame6.png";

import "./Slide.css";

const PokerSlide = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplaySpeed: 5000,
        pauseOnHover: true
    };
    return (
        <div>
            <div className="slide" >
                <Slider {...settings}>
                    {/* <div>
                        <img src={frame1} alt="" />

                    </div>

                    <div>
                        <img src={frame2} alt="" />
                    </div> */}

                    <div>
                        <img src={frame3} alt="" />

                    </div>
                    <div>
                        <img src={frame4} alt="" />

                    </div>
                    <div>
                        <img src={frame5} alt="" />

                    </div>
                    <div>
                        <img src={frame6} alt="" />

                    </div>
                </Slider>
            </div>

        </div>
    );
};

export default PokerSlide;
